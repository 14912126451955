<template>
  <div>
    <CRow class="justify-content-center">
      <CCol xl="12" xxl="10">
      <CButtonGroup class="mb-4">
        <CButton :color="t==='occupancy'?'primary':'secondary'" @click="t='occupancy'">{{$t('cloud.title.occupancy')}}</CButton>
        <CButton :color="t==='traffic'?'primary':'secondary'" @click="t='traffic'">{{$t('cloud.title.traffic_around')}}</CButton>
        <CButton :color="t==='visit'?'primary':'secondary'" @click="t='visit'">{{$t('cloud.title.customer_visit')}}</CButton>
        <CButton :color="t==='queue'?'primary':'secondary'" @click="t='queue'">{{$t('cloud.title.queue_management')}}</CButton>
        <CButton :color="t==='gender'?'primary':'secondary'" @click="t='gender'">{{$t('cloud.title.gender_counter')}}</CButton>
        <CButton :color="t==='age'?'primary':'secondary'" @click="t='age'">{{$t('cloud.title.age_counter')}}</CButton>
      </CButtonGroup>

      <Occupancy v-if="t==='occupancy'" />
      <Traffic v-if="t==='traffic'" />
      <Visit v-if="t==='visit'" />
      <WaitingQueue v-if="t==='queue'" />
      <Gender v-if="t==='gender'" />
      <Age v-if="t==='age'" />
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Occupancy from './occupancy/Occupancy.vue'
import Traffic from './traffic/Traffic.vue'
import Visit from './customer-visit/CustomerVisit.vue'
import WaitingQueue from './queue/Queue.vue'
import Gender from './gender/Gender.vue'
import Age from './age/Age.vue'

export default {
  name: 'RetailCounterSetting',

  components: {
    Occupancy,
    Traffic,
    Visit,
    WaitingQueue,
    Gender,
    Age
  },

  data () {
    return {
      t: 'occupancy'
    }
  },
}
</script>